import { FormControl,  TextField, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';

const OutlinedInputField = props => {
  const { label, name, makedStyles, rules, control, required, ...rest } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { isTouched },
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue || '',
  });

  return (
    <FormControl style={{width: '100%'}} className={makedStyles}>
      <TextField
        fullWidth
        label={label}
        required={required}
        id={name}
        inputRef={ref}
        {...inputProps}
        {...rest}
      />
      {errors?.[name] && (
        <FormHelperText className='mb-5' error id={name} >
          {errors?.[name]?.message}
          {errors?.[name]?.type === 'minLength' && `Must be at least ${rules.minLength} characters`}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default OutlinedInputField
