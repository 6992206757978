import { fork, all } from 'redux-saga/effects';
import commonSagas from 'modules/common/saga';
import authSagas from 'modules/auth/data/saga';
import reportsSagas from 'modules/reports/data/saga';

export default function* rootSaga() {
  yield all([
    fork(commonSagas),
    fork(authSagas),
    fork(reportsSagas),
  ]);
}
