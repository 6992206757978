import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { REQUEST } from 'modules/common/actions';
import { push } from 'connected-react-router';
import { unSetSessionCookies, setSessionCookies } from 'modules/common/utils';

import authActions, { SIGNUP, SIGNIN, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from 'modules/auth/data/actions';
import { signup, signin, signout, forgotPassword, changePassword } from 'services/auth';

export function* handleSignin({ payload }) {
  try {
    const { data } = yield call(signin, payload);
    const accessTokenData = JSON.parse(atob(data.accessToken.split('.')[1]));

    setSessionCookies({ user: accessTokenData.userData, accessToken: data.accessToken });
    yield put(authActions.signin.success(accessTokenData));

    // const userData = yield call(userServices.getUser, '', true)
    // yield put(usersActions.getUser.success(userData))

    yield put(push('/reports/day'));
  } catch (error) {
    console.log(error);
    yield call(toast.error, 'Неправильный логин или пароль!');
    yield put(authActions.signin.failure(error));
  }
}

export default function* authWatcher() {
  yield takeLatest(SIGNIN.REQUEST, handleSignin);
}
