import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';

import commonActions from 'modules/common/actions';
import reportsActions from 'modules/reports/data/actions';

import TopBlueBlock from 'components/Blocks/TopBlueBlock/TopBlueBlock';
import TotalOrderStatusesBlock from 'components/Blocks/TotalOrderStatusesBlock/TotalOrderStatusesBlock';
import CustomerYearsOldBlock from 'components/Blocks/CustomerYearsOldBlock/CustomerYearsOldBlock';
import CustomerTotal from 'components/Blocks/CustomerTotal/CustomerTotal';
import TotalOrderServicesBlock from 'components/Blocks/TotalOrderServicesBlock/TotalOrderServicesBlock';
import TotalMasterOrdersBlock from 'components/Blocks/TotalMasterOrdersBlock/TotalMasterOrdersBlock';
import TotalMoneyToAreaBlock from 'components/Blocks/TotalMoneyToAreaBlock/TotalMoneyToAreaBlock';
import DatePicker from 'components/Filters/DatePicker/DatePicker';
import TurnBlock from 'components/Blocks/TurnBlock/TurnBlock';
import TotalMoneyToWorplaceBlock from 'components/Blocks/TotalMoneyToWorplaceBlock/TotalMoneyToWorplaceBlock';
import Loading from 'components/Loading/Loading';

import './Day.scss';

const Day = () => {
	const dispatch = useDispatch();
	const { watch, register } = useForm();
	const { loading, dayReport } = useSelector(state => state.reportsReducer);
	const { workPoints } = useSelector(state => state.commonReducer);

	const [date, setDate] = useState(moment().format('DD.MM.YYYY'));

	useEffect(() => {
		dispatch(commonActions.getWorkPoints.request());
	}, [])

	useEffect(() => {
		const workPointId = watch('workPointId');
		if (workPointId && date) {
			dispatch(reportsActions.getDayReport.request(workPointId, moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')));
		}
	}, [watch('workPointId'), date])

	let dataList = '';

	if (dayReport) {
		dataList = <div className='content'>
			<div className='left'>
				<TopBlueBlock
					header='Продажи'
					ago='week'

					leftHeader='Выручка'
					leftData={dayReport.total.money + ' p'}
					leftAgo={dayReport.total.moneyWeekAgo + ' p'}

					rightHeader='Ср. чек'
					rightData={dayReport.averageReceipt + ' p'}
					rightAgo={dayReport.averageReceiptWeekAgo + ' p'}
				/>
				<TotalMoneyToWorplaceBlock
					agoParam='week'
					now={dayReport.total.proceedsWorkplaces}
					old={dayReport.total.proceedsWorkplacesWeekAgo}
				/>

				<TotalMoneyToAreaBlock
					agoParam='week'
					now={dayReport.revenueToArea}
					ago={dayReport.revenueToAreaWeekAgo}
				/>
			</div>

			<div className='center'>
				<TopBlueBlock
					header='Заказы'
					ago='week'

					leftHeader='Всего заказов'
					leftData={dayReport.total.orders}
					leftAgo={dayReport.total.ordersWeekAgo}

					rightHeader='Заказов на чел в час'
					rightData={0}
					rightAgo={0}
				/>

				<TurnBlock
					orderInProgress={dayReport.orderInProgress}
					turn={dayReport.turn}
				/>

				<TotalOrderStatusesBlock
					orderStatuses={dayReport.total.orderStatuses}
				/>

				<TotalOrderServicesBlock
					orderServices={dayReport.total.orderServices}
				/>

				<TotalMasterOrdersBlock
					mastersTotalOrders={dayReport.total.mastersTotalOrders}
				/>
			</div>

			<div className='right'>
				<TopBlueBlock
					header='Клиенты'
					ago='week'

					leftHeader='Ср. вр. Ожидания услуги'
					leftData={dayReport.averageWaitingAndLeadTime[0]['avg_time_wait']}
					leftAgo={dayReport.averageWaitingAndLeadTimeWeekAgo[0]['avg_time_wait']}

					rightHeader='Ср. вр. оказания услуги'
					rightData={dayReport.averageWaitingAndLeadTime[0]['avg_time_made']}
					rightAgo={dayReport.averageWaitingAndLeadTimeWeekAgo[0]['avg_time_made']}
				/>

				<CustomerTotal
					ago='week'
					newTotal={dayReport.customers.new.totalToday}
					newTotalAgo={dayReport.customers.new.totalWeekAgo}
					oldTotal={dayReport.customers.old.totalToday}
					oldTotalAgo={dayReport.customers.old.totalWeekAgo}
				/>

				<CustomerYearsOldBlock
					totalNew={dayReport.customers.new.yearsOld.total}
					totalOld={dayReport.customers.old.yearsOld.total}
					allNew={dayReport.customers.new.yearsOld.all}
					allOld={dayReport.customers.old.yearsOld.all}
				/>
			</div>
		</div>;
	};

	return (
		<div id='reports_day'>
			<div className='filter'>
				<TextField
					select
					label='Рабочая точка'
					{...register('workPointId')}
				>
					{workPoints?.map(({ id, name }) => (
						<MenuItem value={id}>{name}</MenuItem>
					))}
				</TextField>
				<DatePicker
					selectedDay={date}
					callBack={setDate}
				/>

			</div>

			{loading ? <Loading /> : dataList}

		</div>
	);
}

export default Day
