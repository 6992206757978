import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import configureStore, { history } from 'store';
import { ErrorBoundary } from 'shared/Components';
import { mainTheme } from 'shared/mainTheme';
import 'style/global.scss';

import Routes from './routes';

const store = configureStore({});

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <ErrorBoundary>
          <Suspense fallback={<CircularProgress />}>
            <Routes />
            <ToastContainer transition={Zoom}/>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

export default App
