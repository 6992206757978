import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

// import authActions from 'modules/auth/data/actions';
import { clearLocalStorage } from 'modules/common/utils';
import './ProfileMenu.scss';

const MenuWrapper = styled(Menu)`
    .MuiMenu-paper {
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 52%);
        width: 176px !important;
        top: 95px !important;

        max-height: 247px !important;
        ul {
            li.MuiMenuItem-root {
                padding: 16px 24px 16px 24px;
            }
        }
    }

    @media screen and (min-width: 600px) {
        .hide-menu-item {
            display: none;
        }
    }
`;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ProfileMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();


    const user = useSelector((state) => state.authReducer.user);
    const options = [
        // 'My Profile',
        // 'Linked Account',
        // 'Change Password',
        'Выйти',
    ];
    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = useState(null);
    const _open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const role = user.role;
    // const userName = `${firstName} ${lastName}`;

    // const handleLogOut = () => {
    //     dispatch(authActions.signout.request({}));
    // };

    const performAction = (opt) => {
        switch (opt) {
            case options[0]:
                //logout
                clearLocalStorage();
                history.push('/signin');
                document.location.reload();
                return;
            default:
                setAnchorEl(null);
        }
    };

    const actionHandle = (opt) => {
        performAction(opt);
        setAnchorEl(null);
    };
    const classes = useStyles();
    return (
        <div className='profile-wrapper'>
            <div className={classes.root}>
                <Avatar className={'orange-color'}>
                </Avatar>
            </div>

            <div>
                <IconButton
                    // style={{ color: 'white' }}
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                >
                    <ArrowDropDownIcon />
                </IconButton>
                <MenuWrapper
                    id='long-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={_open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            selected={option === 'Pyxis'}
                            onClick={() => actionHandle(option)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </MenuWrapper>
            </div>
        </div>
    );
};
export default ProfileMenu;
