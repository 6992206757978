
import React from 'react';

import './TurnBlock.scss';


class TurnBlock extends React.Component {

    render() {
        let countTurtItems = 0;
        return (
            <div className="turn">
                        <ul>
                            {this.props.orderInProgress.map(item => {
                                return (
                                    <li>№ {item.turnNum} стрижет {item.masterName}</li>
                                )
                            })}
                            {this.props.orderInProgress.length === 0 && <li>Нет выполняемых заказов</li>}
                        </ul>
                         В очереди: {

                            this.props.turn.map(item => {
                                countTurtItems++;
                                let punctuation = ',';
                                if (this.props.turn.length === countTurtItems) {
                                    punctuation = '.';
                                }
                                return (
                                    <span> {item.turnNum}{punctuation} </span>
                                )
                            })}
                        {this.props.turn.length === 0 && '-'}

                    </div>
        );
    }
}


export default TurnBlock;