import React from 'react';

import './TotalMoneyToAreaBlock.scss';


class TotalMoneyToAreaBlock extends React.Component {

    render() {
        let agoParam;
        if(this.props.agoParam === 'week'){
            agoParam = 'неделю';
        } else {
            agoParam = 'месяц';
        }
        return (
            <div className="revenue_to_area">
                <h3>Выручка на м2 общей площади</h3>
                <div className="data">
                    {this.props.now} р
                </div>
                <div className="week_ago">
                    {this.props.ago} р {agoParam} назад
                </div>
            </div>
        );
    }
}


export default TotalMoneyToAreaBlock;