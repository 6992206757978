

import React from 'react';

import './TotalMasterOrdersBlock.scss';


class TotalMasterOrdersBlock extends React.Component {

    render() {

        return (
            <div className="total_master_orders_block">
            <h3>Всего выполнено заказов мастерами</h3>
            <ul>
                {this.props.mastersTotalOrders.map(item => {
                    return (
                        <li>{item.masterName} - {item.total}</li>
                    )
                })}
                {this.props.mastersTotalOrders.length === 0 && "Нет начатых сессий"}

            </ul>
        </div>
        );
    }
}


export default TotalMasterOrdersBlock;