import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Box, } from '@mui/material';
import styled from 'styled-components';
import { ToastContainer, Zoom } from 'react-toastify';
import { MainMenu, ProfileMenu } from './components';
import Logo from 'assets/images/Logo.png';

const MainMenuWrapper = styled(Box)`
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 52%);
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 2;
  
  .logo {
    height: 50px;
    margin-right: 16px;
  }

  .link {
    font-weight: bold;
    text-decoration: none;
    color: #000;
    margin-right: 16px;
  }
  
  .active-link {
    color: #B30101;
  }
`;

const ContentWrapper = styled(Box)`
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media only screen and (max-width: 600px) {
    padding-bottom: 90px;
  }
`;

const Main = ({ children, contentClassName, ...props }) => {
  return (
    <Box>
      <MainMenuWrapper className='d-flex justify-content-space-between align-items-center'>
        <div className="d-flex align-items-center">
          {/* <img className='logo' src={Logo} alt="" srcset="" /> */}
          <NavLink className='link' activeClassName='active-link' to='/reports/day'>День</NavLink>
          <NavLink className='link' activeClassName='active-link' to='/reports/month'>Месяц</NavLink>
        </div>
        <MainMenu />
        <ProfileMenu />
      </MainMenuWrapper>
      <div style={{ padding: '0 20px' }} className={contentClassName}>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
