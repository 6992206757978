import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';

import commonActions from 'modules/common/actions';
import reportsActions from 'modules/reports/data/actions';

import TopBlueBlock from 'components/Blocks/TopBlueBlock/TopBlueBlock';
import TotalOrderStatusesBlock from 'components/Blocks/TotalOrderStatusesBlock/TotalOrderStatusesBlock';
import CustomerYearsOldBlock from 'components/Blocks/CustomerYearsOldBlock/CustomerYearsOldBlock';
import CustomerTotal from 'components/Blocks/CustomerTotal/CustomerTotal';
import MonthPicker from 'components/Filters/MonthPicker/MonthPicker';
import TotalOrderServicesBlock from 'components/Blocks/TotalOrderServicesBlock/TotalOrderServicesBlock';
import TotalMasterOrdersBlock from 'components/Blocks/TotalMasterOrdersBlock/TotalMasterOrdersBlock';
import AvgTimeWaitMadeChart from 'components/Chart/AvgTimeWaitMadeChart/AvgTimeWaitMadeChart';
import TotalMoneyYearChart from 'components/Chart/TotalMoneyYearChart/TotalMoneyYearChart';
import TotalMoneyToAreaBlock from 'components/Blocks/TotalMoneyToAreaBlock/TotalMoneyToAreaBlock';
import Loading from 'components/Loading/Loading';

import './Month.scss';

const Month = () => {
	const dispatch = useDispatch();
	const { watch, register } = useForm();
	const { loading, monthReport } = useSelector(state => state.reportsReducer);
	const { workPoints } = useSelector(state => state.commonReducer);

	const [date, setDate] = useState(moment().format('MM/YYYY'));

	useEffect(() => {
		dispatch(commonActions.getWorkPoints.request());
	}, [])

	useEffect(() => {
		const workPointId = watch('workPointId');
		if (workPointId && date) {
			dispatch(reportsActions.getMonthReport.request(workPointId, moment(date, 'MM/YYYY').format('YYYY-MM-DD')));
		}
	}, [watch('workPointId'), date])

	let dataList = '';

	if (monthReport) {
		const { total, customers } = monthReport;
		
		dataList = <div className='content'>
			<div className='left'>
				<TopBlueBlock
					header='Продажи'
					ago='month'

					leftHeader='Выручка'
					leftData={total.money + ' p'}
					leftAgo={total.moneyMonthAgo + ' p'}

					rightHeader='Ср. чек'
					rightData={monthReport.averageReceipt + ' p'}
					rightAgo={monthReport.averageReceiptMonthAgo + ' p'}
				/>
				<TotalMoneyYearChart
					moneyYear={total.moneyYear}
				/>

				<TotalMoneyToAreaBlock
					agoParam='month'
					now={monthReport.revenueToArea}
					ago={monthReport.revenueToAreaMonthAgo}
				/>
			</div>

			<div className='center'>
				<TopBlueBlock
					header='Заказы'
					ago='month'

					leftHeader='Всего заказов'
					leftData={total.orders}
					leftAgo={total.ordersMonthAgo}

					rightHeader='Заказов на чел в час'
					rightData={0}
					rightAgo={0}
				/>

				<TotalOrderServicesBlock
					orderServices={total.orderServices}
				/>

				<TotalOrderStatusesBlock
					orderStatuses={total.orderStatuses}
				/>

				<TotalMasterOrdersBlock
					mastersTotalOrders={total.mastersTotalOrders}
				/>
			</div>

			<div className='right'>
				<TopBlueBlock
					header='Клиенты'
					ago='month'

					leftHeader='Ср. вр. Ожидания услуги'
					leftData={monthReport.averageWaitingAndLeadTime[0]['avg_time_wait']}
					leftAgo={monthReport.averageWaitingAndLeadTimeMonthAgo[0]['avg_time_wait']}

					rightHeader='Ср. вр. оказания услуги'
					rightData={monthReport.averageWaitingAndLeadTime[0]['avg_time_made']}
					rightAgo={monthReport.averageWaitingAndLeadTimeMonthAgo[0]['avg_time_made']}
				/>

				<AvgTimeWaitMadeChart
					avgTimeWaitMade={monthReport.averageWaitingAndLeadTimeYears}
				/>

				<CustomerTotal
					ago='month'
					newTotal={customers.new.totalMonth}
					newTotalAgo={customers.new.totalMonthAgo}
					oldTotal={customers.old.totalMonth}
					oldTotalAgo={customers.old.totalMonthAgo}
				/>

				<CustomerYearsOldBlock
					totalNew={customers.new.yearsOld.total}
					totalOld={customers.old.yearsOld.total}
					allNew={customers.new.yearsOld.all}
					allOld={customers.old.yearsOld.all}
				/>
			</div>
		</div>;
	};

	return (
		<div id='reports_month'>
			<div className='filter'>
				<TextField
					select
					label='Рабочая точка'
					{...register('workPointId')}
				>
					{workPoints?.map(({ id, name }) => (
						<MenuItem value={id}>{name}</MenuItem>
					))}
				</TextField>
				<MonthPicker
					selectedMonth={date}
					callBack={setDate}
				/>
			</div>

			{loading ? <Loading /> : dataList}

		</div>
	);
}

export default Month
