import reduce from 'lodash/reduce';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_MESSAGE = 'SET_MESSAGE';

export const createRequestTypes = base => {
  const CONST = reduce(
    [REQUEST, SUCCESS, FAILURE],
    (acc, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    {}
  );
  CONST.toString = () => base;
  return CONST;
};

export function action(type, payload = {}) {
  return { type, ...payload };
}

export const GET_WORK_POINTS = createRequestTypes('GET_WORK_POINTS');

// Async actions
const actions = {
  getWorkPoints: {
    request: () => action(GET_WORK_POINTS.REQUEST),
    success: data => action(GET_WORK_POINTS.SUCCESS, { payload: data }),
    failure: error => action(GET_WORK_POINTS.FAILURE, { payload: error }),
  },

  setMessage: (reducer, code, message) => action(SET_MESSAGE, { payload: { reducer, code, message } }),
  setError: (reducer, code, error) => action(SET_ERROR, { payload: { reducer, code, error } }),
  setLoading: (reducer, code, status) => action(SET_LOADING, { payload: { reducer, code, status } }),
};

export default actions;
