import axios from 'axios';
import { getFileNameFromContentDisposition } from 'utils/helperFunctions';

import { API_BASE_URL } from 'config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: API_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getWorkPoints: () => request.get('/workpoints'),
  // common configuration apis goes here
  downloadFile: (link, clientFileName) => {
    return request.get(link, { responseType: 'blob' })
      .then((response) => {
        const fileName = getFileNameFromContentDisposition(response.headers['content-disposition']) || 'fileName.pdf';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href  = url;
        link.download = clientFileName ? clientFileName : fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url)
      })
  }
};

export default apiWrapper(api);
