import { Switch, Redirect, Route } from 'react-router-dom';

import RouteWithLayout from 'shared/RouteWithLayout';
import { Minimal as MinimalLayout, Main as MainLayout } from 'shared/Components/Layout';
import { NotFound } from 'shared/Errorpages';

import { view as authView } from 'modules/auth';
import { view as reportsView } from 'modules/reports';
import { routes } from 'modules/common/constants';

const Routes = () => (
  <Switch>
    <Redirect exact from='/' to={routes.signIn} />

    <RouteWithLayout
      exact
      path={routes.signIn}
      component={authView.SignIn}
      layout={MinimalLayout}
      title='Добро пожаловать в TOTO IS'
    />

    <RouteWithLayout
      path={routes.dayReport}
      component={reportsView.DayReport}
      layout={MainLayout}
      protectedRoute
      title='Отчет за день'
    />

    <RouteWithLayout
      path={routes.monthReport}
      component={reportsView.MonthReport}
      layout={MainLayout}
      protectedRoute
      title='Отчет за месяц'
    />

    <Route path='/not-found' component={NotFound} />

    <Redirect to='/not-found' />
  </Switch>
);

export default Routes
