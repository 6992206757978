import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import './DatePicker.scss';
import ArrowRight from 'assets/img/ArrowRight.png';
import CalendarIcon from 'assets/img/CalendarIcon.png';


class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openStatusCalendar: false
        }
        this.dayPicker = React.createRef();
    }
    selectDay = (day) => {
        this.props.callBack(moment(day).format('DD.MM.YYYY'))
       
        this.setState({
            openStatusCalendar: false
        })
    }

    selectPreviousDay = (el) => {
        this.props.callBack(el.target.innerHTML)
    }

    showHideCalendar = (event) => {
        if (this.state.openStatusCalendar) {
            this.dayPicker.hideDayPicker()
            this.setState({
                openStatusCalendar: false
            })
        } else {
            this.dayPicker.showDayPicker()
            this.setState({
                openStatusCalendar: true
            })
        }
    }


    render() {

        return (
            <div className="datePickerContainer">
            <div className="previous_day" onClick={this.selectPreviousDay}>
                {moment(this.props.selectedDay, 'DD.MM.YYYY').subtract(1, 'days').format('DD.MM.YYYY')}
            </div>
            <div className="arrow_right">
                <img src={ArrowRight} alt="" srcset="" />
            </div>
            <div className="selected_day">
                <DayPickerInput
                    ref={el => this.dayPicker = el}
                    format={'DD.MM.YYYY'}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    className="pupkin"
                    component={props => <input {...props} value={this.props.selectedDay}

                        style={{
                            border: 'none',
                            fontFamily: 'Comfortaa-Bold',
                            width: '86px',
                            fontSize: '16px',
                            background: 'transparent'
                        }} 
                        />}
                    style={{
                        left: '-133px',
                        top: '10px',
                        background: '#EBEBF0',
                        borderRadius: '4px',
                        padding: '4px',
                       
                    }}
                    onDayChange={this.selectDay}
                    dayPickerProps={{
                        month: new Date(moment(this.props.selectedDay, 'DD.MM.YYYY').format()),
                        selectedDays: new Date(moment(this.props.selectedDay, 'DD.MM.YYYY').format()),
                        locale: "ru",
                        localeUtils: MomentLocaleUtils,
                        style: {
                            left: '-133px',
                            top: '10px',
                            background: '#EBEBF0',
                            borderRadius: '4px',
                            padding: '4px',
                           
                        }
                    }
                    } />
            </div>
            <div className="calendar_icon" onClick={this.showHideCalendar}>
                <img src={CalendarIcon} alt="" srcset="" />
            </div>
        </div>
        );
    }
}


export default DatePicker;