import React from 'react';
import { Line } from 'react-chartjs-2';
import './AvgTimeWaitMadeChart.scss';


class AvgTimeWaitMadeChart extends React.Component {

    render() {
        let labels = [], dataWait = [], dataMade = [];
        let arr = this.props.avgTimeWaitMade;
        for (let i = 0; i < arr.length; i++) {
            labels.push(arr[i].month);
            dataWait.push(arr[i].avg_time_wait);
            dataMade.push(arr[i].avg_time_made);

        }
        console.log(dataWait, dataMade)
        return (
            <div className="line_chart_wrapper">
                <h3><span style={{ color: '#A6CEE3' }}>Ожидание </span> и <span style={{ color: '#E01313' }}>оказание</span> услуг за посл. 12 месяцев</h3>

                <div className="">
                    <Line
                        data={{
                            labels: labels,
                            datasets: [{
                                label: 'Ожидание',
                                data: dataWait,
                                fill: false,
                                lineTension: 0.3,
                                // backgroundColor: 'rgba(75,192,0,0.4)',
                                borderColor: '#A6CEE3',
                                borderCapStyle: 'butt',
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: 'bevel',// 'miter',
                                pointBorderColor: '#A6CEE3',
                                pointBackgroundColor: '#A6CEE3',
                                pointBorderWidth: 1,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                pointHoverBorderColor: 'rgba(220,220,220,1)',
                                pointHoverBorderWidth: 2,
                                pointRadius: 3,
                                pointHitRadius: 10,
                            },
                            {
                                label: 'Оказание',
                                data: dataMade,
                                fill: false,
                                lineTension: 0.3,
                                // backgroundColor: 'rgba(75,192,0,0.4)',
                                borderColor: '#E01313',
                                borderCapStyle: 'butt',
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: 'bevel',// 'miter',
                                pointBorderColor: '#E01313',
                                pointBackgroundColor: '#E01313',
                                pointBorderWidth: 1,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: '#E01313',
                                pointHoverBorderColor: 'rgba(220,220,220,1)',
                                pointHoverBorderWidth: 2,
                                pointRadius: 3,
                                pointHitRadius: 10,
                            }]
                        }}
                        width={100}
                        height={50}
                        options={{
                            legend: {
                                display: false
                            },
                            tooltips: {
                                mode: 'x'
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,

                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </div>

        );
    }
}


export default AvgTimeWaitMadeChart;