import './Loading.scss';

const Loading = () => (
	<div id='loading'>
		<div class='cssload-preloader-box'>
			<div>T</div>
			<div>O</div>
			<div>T</div>
			<div>O</div>
		</div>
	</div>
);

export default Loading
