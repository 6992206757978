import React from 'react';

import './CustomerTotal.scss';


class CustomerTotal extends React.Component {

    render() {
        let ago;
        if (this.props.ago === 'week') {
            ago = 'неделю';
        } else {
            ago = 'месяц';
        }
        return (
            <div className="total_customers">
                <div className="new">
                    <h3>Новых клиентов</h3>
                    <div className="data">
                        {this.props.newTotal}
                    </div>
                    <div className="week_ago">
                        {this.props.newTotalAgo} {ago} назад
                    </div>
                </div>
                <div className="old">
                    <h3>Старых клиентов</h3>
                    <div className="data">
                        {this.props.oldTotal}
                    </div>
                    <div className="week_ago">
                        {this.props.oldTotalAgo} {ago} назад
                    </div>
                </div>
            </div>
        );
    }
}


export default CustomerTotal;