import React, { useRef, useState } from 'react';

import { Box, Icon } from '@mui/material';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const UserPic = ({ src, name }) => {
  const UserNameHighlight = styled(Box)`
    margin: -2px 8px 0 12px;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: #fff9d9;
    color: #ffd044;
    font-size: 15px;
  `;

  const StyledImg = styled.img`
    margin: -2px 8px 0 12px;
    border-radius: 8px;
    height: 30px;
    width: 30px;
  `;

  return src ? (
    <StyledImg alt='user-profile-pic' src={src} />
  ) : (
    <UserNameHighlight>{name}</UserNameHighlight>
  );
};

const Container = styled(Box)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiMenuItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #efefef;
    padding-right: 50px;

    svg {
      margin-right: 5px;
    }
  }
  .MuiMenuItem-root:last-child {
    border-bottom: none;
  }
`;

const StyledText = styled('div')`
  font-size: 14px;
  margin-top: 3px;
  margin-left: 12px;
  text-transform: initial;
`;

const ProfileMenu = (props) => {
  const { profile } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();
  //   const { logout } = useRoutes();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const openProfilePage = () => {
    setOpen(false);
    // history.push(regularUserRoutes.settings.profile.getPath());
  };

  const logoutUser = () => {
    setOpen(false);
    // logout();
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  // React.useEffect(() => {
  // 	if (prevOpen.current === true && open === false) {
  // 		anchorRef.current!.focus();
  // 	}

  // 	prevOpen.current = open;
  // }, [open]);

  return (
    <Container>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <StyledText>{!!profile ? profile.firstName : ''}</StyledText>
        <UserPic
          src={profile?.photos[0]?.profilePicture ?? ''}
          name={!!profile ? profile.firstName : ''}
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-end'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={openProfilePage}>
                    <Icon fill={'#192038'} name={'user-cog'} width={16} height={16} />
                    Profile Details
                  </MenuItem>
                  <MenuItem onClick={logoutUser}>
                    <Icon fill={'#ff6138'} name={'log-out'} width={16} height={16} />
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default ProfileMenu;
