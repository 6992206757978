import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Image,  } from 'shared/Atoms';
// import NotFoundImg from 'assets/images/error_bg.png';

const StyledNotFoundWrapper = styled(Box)`
	background-color: #43487a;
	height: 100vh;
`;

const NotFound = () => {
	return (
		<StyledNotFoundWrapper className='center-content'>
			{/* <Image src={NotFoundImg} alt='404 not found' /> */}
			<Typography className='text-center semi-bold text-white' fontSize='2.29'>
				Упсс,
				<br />
				кажется вы потерялись.
			</Typography>
		</StyledNotFoundWrapper>
	);
};

export default NotFound;
