
import React from 'react';
import { Line } from 'react-chartjs-2';
import './TotalMoneyYearChart.scss';


class TotalMoneyYearChart extends React.Component {

    render() {
        let labels = [], data = [];
            let arr = this.props.moneyYear;
            for (let i = 0; i < arr.length; i++) {
                labels.push(arr[i].month);
                data.push(arr[i].total)

            }

        return (
            <div className="line_chart_wrapper">
            <h3>Выручка за последние 12 месяцев</h3>

            <div className=""> <Line
                data={{
                    labels: labels,
                    datasets: [{
                        label: 'Выручка',
                        data: data,
                        fill: false,
                        lineTension: 0.3,
                        // backgroundColor: 'rgba(75,192,0,0.4)',
                        borderColor: '#A6CEE3',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'bevel',// 'miter',
                        pointBorderColor: '#A6CEE3',
                        pointBackgroundColor: '#A6CEE3',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 3,
                        pointHitRadius: 10,
                    }]
                }}
                width={100}
                height={50}
                options={{
                    // title: {
                    //     display: true,
                    //     text: ''
                    // },
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,

                            }
                        }]
                    }
                }}
            />
            </div>
        </div>

        );
    }
}


export default TotalMoneyYearChart;