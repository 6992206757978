import { SIGNUP, SIGNIN, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from './actions';
import { getUserId, getUser } from 'modules/common/utils';

const initialState = {
  isLoggedIn: !!getUserId(),
  user: getUser(),
  loading: false,
  error: false,
};

function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGNUP.REQUEST: return { ...state, loading: true, error: false, isLoggedIn: false };
    case SIGNUP.SUCCESS: return { ...state, loading: false, error: false };
    case SIGNUP.FAILURE: return { ...state, loading: false, error: payload.message, isLoggedIn: false };

    case SIGNIN.REQUEST: return { ...state, loading: true, error: false, isLoggedIn: false };
    case SIGNIN.SUCCESS: return { ...state, loading: false, error: false, isLoggedIn: true, user: payload.user };
    case SIGNIN.FAILURE: return { ...state, loading: false, error: payload.message, user: payload.user, isLoggedIn: false, };

    case SIGNOUT.REQUEST: return { ...state, loading: true, error: false };
    case SIGNOUT.SUCCESS: return { ...state, loading: false, error: false, isLoggedIn: false };
    case SIGNOUT.FAILURE: return { ...state, loading: false, error: payload.message, isLoggedIn: false };

    case FORGOT_PASSWORD.REQUEST: return { ...state, loading: true, error: false, username: payload.username };
    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        changePasswordVisible: true,
        forgotPasswordVisible: false,
        isForgotForm: true,
      };
    case FORGOT_PASSWORD.FAILURE: return { ...state, loading: false, error: payload.message, };

    case CHANGE_PASSWORD.REQUEST: return { ...state, loading: true, error: false };
    case CHANGE_PASSWORD.SUCCESS: return { ...state, loading: false, error: false, changePasswordVisible: false };
    case CHANGE_PASSWORD.FAILURE: return { ...state, loading: false, error: payload.message, };

    default:
      return state;
  }
}

export default authReducer;