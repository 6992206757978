import axios from 'axios';
import { AUTH_BASE_URL } from 'config';
const host = window.location.origin;
export const signup = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${AUTH_BASE_URL}/auth/register`, { username: email, password })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signin = async ({ email, password }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${AUTH_BASE_URL}/auth/login`, { login: email, password })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changePassword = (payload) => {
  const { email, oldPassword, newPassword } = payload;
  return new Promise((resolve, reject) => {
    axios
      .post(`${AUTH_BASE_URL}/auth/reset-password`, { email, oldPassword, newPassword })
      .then((user) => {})
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgotPassword = (payload) => {
  const { email } = payload;

  return new Promise((resolve, reject) => {
    // Receving Error : Reason Internal Error
    axios
      .post(`${AUTH_BASE_URL}/auth/forgot-password`, { email })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signout = () => {
  const refreshToken = localStorage.getItem(`${host}_tokens`);
  const token = JSON.parse(refreshToken).refresh.token;
  return new Promise((resolve, reject) => {
    axios
      .post(`${AUTH_BASE_URL}/auth/logout`, { refreshToken: token })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getNextAuthRouteAfterSignIn(data, medicalRecordRequests, routes,  medicalRecordRequestStatuses) {
  if(data.onboardingProcessCompleted){
    return '/overview';
  }
  if (medicalRecordRequests.length > 0) {
    const draftsMedicalRecordRequests = medicalRecordRequests.filter((value) => value.status === medicalRecordRequestStatuses.DRAFT);
    if (draftsMedicalRecordRequests.length > 0) {
      return routes.onboardingStepFour;
    }
    const savedMedicalRecordRequests = medicalRecordRequests.filter((value) => value.status === medicalRecordRequestStatuses.PROVIDER_INFORMATION_SAVED)
    if(savedMedicalRecordRequests.length > 0){
      return routes.onboardingStepFive;
    } else {
      return routes.onboardingStepSix;
    }
  }
  if (data.identityDocumentOverwrite !== null) {
    return routes.onboardingStepThree;
  }
  if (data.identityDocument !== null) {
    return routes.onboardingStepTwo;
  } else {
    return routes.onboardingStepOne;
  }
}
