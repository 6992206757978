import moment from 'moment';
import reduce from 'lodash/reduce';

import { DATE_FORMAT } from 'shared/constants';

export const replaceKeysByMapper = (obj, mapper, placeholderKey = '') =>
  reduce(
    obj,
    (result, value, key) => {
      const newKey = mapper[key] || placeholderKey;
      result[newKey] = value;
      return result;
    },
    {}
  );

export const formatDate = dateObj => moment(dateObj).format(DATE_FORMAT);

export const getFileFormat = url => {
  const urlArr = url.split('.');
  return urlArr[urlArr.length - 1];
};

export const isDate = date => {
  date = new Date(date);
  return date instanceof Date && !isNaN(date.valueOf());
};

export const secondsConverter = (seconds) => {
  seconds = parseInt(seconds, 10);
  if(isNaN(seconds)){
    return "invalid data provided";
  }
  const hour = Math.floor(seconds / 60 / 60);
  const minute = Math.floor((seconds / 60) % 60);
  let result ="";
  if(hour > 0){
    result = result.concat(`${hour} h `);
  }
  if(minute > 0){
    result = result.concat(`${minute} m `);
  }
  return result;
};

export const getFileNameFromContentDisposition = (contentDispositionHeader) => {
  if (contentDispositionHeader) {
    let matches = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g);
    if (matches !== null && matches[0]) { 
      return matches[0].replace(/['"]/g, '').split('=')[1];
    }
    return null;
  }
  return null;
};
