import React from 'react';
import styled from 'styled-components';

const ContainerDiv = styled.div`
  background-color: #B30101;
  padding-top: 126px;
  height: 100vh;

  h1 {
    color: #e6e6e6;
  }
  p {
    color: #d2d2d2ef;
    font-size: 18px;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    if (
      this.state.error === null &&
      window.localStorage.chunkReloaded === undefined &&
      error.request &&
      error.request.indexOf('chunk') > -1
    ) {
      window.localStorage.chunkReloaded = true;
      window.location.reload();
    } else {
      window.localStorage.removeItem('chunkReloaded');
    }
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContainerDiv className='w-100 text-center'>
          <h1>Упсс, что-то пошло не так :(</h1>
          <p>Попробуйте перезагрузить страницу.</p>
        </ContainerDiv>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
