import {
  GET_DAY_REPORT,
  GET_MONTH_REPORT,
} from './actions';
import moment from 'moment';

const initialState = {
  loading: false,
  error: false,

  dayReport: null,
  monthReport: null,
};

function reportsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DAY_REPORT.REQUEST: return { ...state, loading: true, error: false };
    case GET_DAY_REPORT.SUCCESS: return { ...state, loading: false, error: false, dayReport: payload.data };
    case GET_DAY_REPORT.FAILURE: return { ...state, loading: false, error: payload.message };

    case GET_MONTH_REPORT.REQUEST: return { ...state, loading: true, error: false };
    case GET_MONTH_REPORT.SUCCESS: return { ...state, loading: false, error: false, monthReport: payload.data };
    case GET_MONTH_REPORT.FAILURE: return { ...state, loading: false, error: payload.message };

    default:
      return state;
  }
}

export default reportsReducer
