
import React from 'react';

import './TotalOrderServicesBlock.scss';


class TotalOrderServicesBlock extends React.Component {

    render() {

        return (
            <div className="total_order_services_block">
                <table>
                    {this.props.orderServices.map(item => {
                        return (
                            <tr>
                                <td>{item.serviceName}</td>
                                <td >-</td>
                                <td>{item.total} шт</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        );
    }
}


export default TotalOrderServicesBlock;