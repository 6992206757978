
import React from 'react';

import './TotalMoneyToWorplaceBlock.scss';


class TotalMoneyToWorplaceBlock extends React.Component {

    render() {
        let ago;
        if (this.props.agoParam === 'week') {
            ago = 'неделю';
        } else {
            ago = 'месяц';
        }
        return (


            <div className="total_money_to_workplace">
                <h3>Выручка на рабочее место</h3>
                <div className="data">
                <div className="left">
                    <table>
                        {
                            this.props.now.map(item => {
                                return (
                                    <tr>
                                        <td>{item.workplace} -</td>
                                        <td>{item.total} p</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
                <div className="right">
                    <table>
                        {
                            this.props.old.map(item => {
                                return (
                                    <tr>
                                       
                                <td>{item.total} p {ago} назад</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
                </div>

            </div>
        );
    }
}


export default TotalMoneyToWorplaceBlock;