import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import commonReducer from 'modules/common/reducer';
import authReducer from 'modules/auth/data/reducer';
import reportsReducer from 'modules/reports/data/reducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    commonReducer,
    authReducer,
    reportsReducer,
  });

export default rootReducer;
