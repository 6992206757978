import moment from 'moment';
import ArrowRight from 'assets/img/ArrowRight.png';
import CalendarIcon from 'assets/img/CalendarIcon.png';
import './MonthPicker.scss';

const MonthPicker = ({ callBack, selectedMonth }) => (
	<div className='month_picker'>
		<div className='side' onClick={(el) => callBack(el.target.innerHTML)}>
			{moment(selectedMonth, 'MM/YYYY').subtract(1, 'month').format('MM/YYYY')}
		</div>

		<div className='arrow_right'><img src={ArrowRight} alt='' srcset='' /></div>
		<div className='selected'>{selectedMonth}</div>
		<div className='arrow_right'><img src={ArrowRight} alt='' srcset='' /></div>

		<div className='side' onClick={(el) => callBack(el.target.innerHTML)}>
			{moment(selectedMonth, 'MM/YYYY').add(1, 'month').format('MM/YYYY')}
		</div>

		<div className='calendar_icon'><img src={CalendarIcon} alt='' srcset='' /></div>
	</div>
);

export default MonthPicker
