// import { ContentWrapperBlockComponent } from 'modules/auth/style';

const ContentWrapperBlock = ({ addProviderSteps, children, ...props }) => (
  <div
    className={`m-auto d-flex-column justify-content-space-between w-100 overflow-auto h-100`} {...props}>
    {children}
  </div>
);

export default ContentWrapperBlock;
