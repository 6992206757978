import { call, put, takeLatest } from 'redux-saga/effects';

import { REQUEST } from 'modules/common/actions';
import reportsApi from 'services/reports';
import reportsActions, { GET_DAY_REPORT, GET_MONTH_REPORT } from './actions';

export function* handleGetDayReport({ workPointId, date }) {
  try {
    const { data } = yield call(reportsApi.getDayReport, workPointId, date);
    yield put(reportsActions.getDayReport.success({ data }));
  } catch (error) {
    yield put(reportsActions.getDayReport.failure(error));
  }
}

export function* handleGetMonthReport({ workPointId, date }) {
  try {
    const { data } = yield call(reportsApi.getMonthReport, workPointId, date);
    yield put(reportsActions.getMonthReport.success({ data }));
  } catch (error) {
    yield put(reportsActions.getMonthReport.failure(error));
  }
}

export default function* reportsWatcher() {
  yield takeLatest(GET_DAY_REPORT[REQUEST], handleGetDayReport);
  yield takeLatest(GET_MONTH_REPORT[REQUEST], handleGetMonthReport);
}
