// import OpenSansRegular from 'assets/fonts/openSans/OpenSans-Regular.ttf';
// import OpenSansSemiBold from 'assets/fonts/openSans/OpenSans-SemiBold.ttf';
// import OpenSansBold from 'assets/fonts/openSans/OpenSans-Bold.ttf';
// import ManropeExtraBold from 'assets/fonts/Manrope/Manrope-ExtraBold.ttf';
// import AmazonEmberRegular from 'assets/fonts/AmazonEmber/AmazonEmber_Rg.ttf';

import placeholderImage from 'assets/images/placeholderImage.svg';

let { innerHeight: pageHight } = window;
// subtracting navigation bar height

export const PAGE_HIGHT = pageHight - 80;

export const organizationAllRightReserved = 'Takeda';

export const API_URLS = {};

export const BUTTON_TEXT_ONLY_COLOR = '#8f9bb3';

export const PLACE_HOLDER_IMAGE = placeholderImage;

export const DEBOUNCE_CONSTANT = 700;


export const DATE_FORMAT = 'YYYY-MM-DD';
export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';

export const PASSWORD_MIN_LENGTH = 6;

export const URL_FOR_RAW_FILE_DOWNLOAD =  'medicalRecordRequests/{medicalRecordRequestId}/rawFile';

export const US_PHONE_MASK = [
  "+",
  "1",
  " ",
  /[2-9]/,
  /\d/,
  /\d/,
  "-",
  /[2-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const ROADMAP = {
  HIVTest: 'HIVTest',
  SARSVaccine: 'SARSVaccine',
  Therapist: 'Therapist',
  AGRT2: 'AGRT2',
};

// to apply font on certain element, simply add a selector here
export const fontsMapper = {
  // OpenSansRegular: {
  //   font: OpenSansRegular,
  //   selectors: ['*', '.text-reg'],
  // },
  // OpenSansSemiBold: {
  //   font: OpenSansSemiBold,
  //   selectors: ['.sub-tilte', '.MuiFormLabel-root', '.semi-bold', 'label'],
  // },
  // OpenSansBold: {
  //   font: OpenSansBold,
  //   selectors: ['.text-bold'],
  // },
  // ManropeExtraBold: {
  //   font: ManropeExtraBold,
  //   selectors: ['h1', 'h2', 'h3', 'h4', 'h5', '.heading'],
  // },
  // AmazonEmberRegular: {
  //   font: AmazonEmberRegular,
  //   selectors: ['.amazon-font-reg'],
  // }
};
