import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import authActions from 'modules/auth/data/actions';
import { validateEmail } from 'utils/serviceUtils/validators';
import {
  ContentWrapperBlock,
  MainWrapperBlock,
} from 'modules/auth/view/components';
import {
  ButtonMainAccent,
  OutlinedInputField,
  Footer,
} from 'shared/Molecules';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(state => state.authReducer);

  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    dispatch(authActions.signin.request(data, history));
  };

  return (
    <ContentWrapperBlock>
      <MainWrapperBlock>
        <FormControl
          className='d-block'
          style={{width: 350}}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Typography className='text-center mb-15' variant='h3'>TOTO IS</Typography>
          <OutlinedInputField
            makedStyles='mb-20'
            type='email'
            label='Логин'
            placeholder='toto@mail.com'
            name='email'
            control={control}
            rules={{
              required: 'Email обязателен',
              validate: value => validateEmail(value) ? true : 'Email должен быть корректным',
            }}
          />
          <OutlinedInputField
            makedStyles='mb-25'
            type='password'
            label='Пароль'
            placeholder={'• '.repeat(10)}
            name='password'
            control={control}
            rules={{ required: 'Пароль обязателен' }}
          /> 
          <ButtonMainAccent
            fullWidth
            type='submit'
            variant='contained'
            loading={loading}
          >
           Войти
          </ButtonMainAccent>
        </FormControl>
      </MainWrapperBlock>
      <Footer/>
    </ContentWrapperBlock>
  );
};

export default SignIn
