import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@mui/material';
// import { MainMenuRoutes } from 'modules/common/constants';

const MenuWrapper = styled(Box)`
  .link-wrapper {
    position: relative;
  }

  .link:hover {
    text-decoration: none;
  }

  .active .link {
    color: #077b8a !important;
  }

  .active::after {
    content: '';
    width: 24px;
    height: 2px;
    border-radius: 1.5px;
    background-color: #077b8a;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -12px;
  }
`;

const MainMenu = () => {
  const [current, setCurrent] = useState('');

  return (
    <MenuWrapper className='h-100' display={{ xs: 'none', sm: 'flex' }}>
      {/* {MainMenuRoutes.map((route, i) => {
        const classes = [];
        const isActive = route.route == window.location.pathname;
        isActive && classes.push('active');

        return (
          <div
            key={i}
            className={`link-wrapper d-flex align-items-center ${classes.join(' ')}`}
            onClick={(e) => setCurrent(e.key)}
          >
            <Link to={route.route} className='semi-bold link px-20 text-white'>
              {route.label}{' '}
            </Link>
          </div>
        );
      })} */}
    </MenuWrapper>
  );
};

export default MainMenu;
