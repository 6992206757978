import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import styled from 'styled-components';

import CompanyLogo from './logo.svg';

const LeftWrapper = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  background: rgb(158,111,255);
  background: linear-gradient(156deg, #fff 19%, #f9f9f9 104%);
  height: 100vh;
`;

const RightWrapper = styled(Box)`
  height: 100vh;
`;

const SubTitle = styled('div')`
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  line-height: 1.14;
`;

const splitTextIntoLines = (text) => {
  return text.split('\n').map(str => <Typography variant='h1' fontSize='3.43' style={{lineHeight: '1.33'}}>{str}</Typography>);
}

const Minimal = (props) => {
  const { children, title, subTitle } = props;

  return (
    // <Grid className='m-0' container>
    //   <Grid item xs={5}>
    //     <LeftWrapper className='d-flex-column justify-content-center text-white'>
    //       <img src={CompanyLogo} style={{ position: 'absolute', top: '0', width: '9em', marginTop: '1.5em', marginLeft: '.8em' }} alt="CompanyLogo" />
    //       <div className='mb-45 text-dimBlue'>{splitTextIntoLines(title)}</div>
    //       <SubTitle className='sub-tilte text-dimBlue'>{subTitle}</SubTitle>
    //     </LeftWrapper>
    //   </Grid>
    //   <Grid item xs={7}>
        <RightWrapper className='d-flex align-items-center'>{children}</RightWrapper>
    //   </Grid>
    // </Grid>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
