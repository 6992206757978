import { call, put, takeLatest } from 'redux-saga/effects';

import { REQUEST } from 'modules/common/actions';
import commonApi from 'services/common';
import commonActions, { GET_WORK_POINTS } from './actions';

export function* handleGetWorkPoints() {
  try {
    let { data } = yield call(commonApi.getWorkPoints);
    yield put(commonActions.getWorkPoints.success({ data }));
  } catch (error) {
    yield put(commonActions.getWorkPoints.failure(error));
  }
}

export default function* commonWatcher() {
  yield takeLatest(GET_WORK_POINTS[REQUEST], handleGetWorkPoints);
}
