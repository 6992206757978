import axios from 'axios';

import { API_BASE_URL } from 'config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: API_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getDayReport: (workPontId, date) => request.get(`/reports/day/${workPontId}/${date}`),
  getMonthReport: (workPontId, date) => request.get(`/reports/month/${workPontId}/${date}`),
};

export default apiWrapper(api)
