
import React from 'react';

import './TotalOrderStatusesBlock.scss';


class TotalOrderStatusesBlock extends React.Component {

    render() {

        return (
            <div className="total_order_statuses">
                <div className="block">
                    <h3>{this.props.orderStatuses[44].statuseName} </h3>
                    <div className="data">
                        {this.props.orderStatuses[44].total}
                    </div>

                </div>
                <div className="block">
                    <h3>{this.props.orderStatuses[45].statuseName} </h3>
                    <div className="data">
                        {this.props.orderStatuses[45].total}
                    </div>
                </div>
                <div className="block">
                    <h3>{this.props.orderStatuses[46].statuseName} </h3>
                    <div className="data">
                        {this.props.orderStatuses[46].total}
                    </div>
                </div>
            </div>
        );
    }
}


export default TotalOrderStatusesBlock;