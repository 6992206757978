import {
  SET_ERROR,
  GET_WORK_POINTS,
} from './actions';

const initialState = {
  workPoints: [],
  loadingWorkPoints: false,
  errorWorkPoints: false,
};

function commonReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ERROR: return { ...state, error: payload };

    case GET_WORK_POINTS.REQUEST: return { ...state, loadingWorkPoints: true, errorWorkPoints: false };
    case GET_WORK_POINTS.SUCCESS: return { ...state, loadingWorkPoints: false, errorWorkPoints: false, workPoints: payload.data };
    case GET_WORK_POINTS.FAILURE: return { ...state, loadingWorkPoints: false, errorWorkPoints: payload.message, }
    

    default:
      return state;
  }
}

export default commonReducer;
