import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';
import styled, { css } from 'styled-components';
import { Icon,  } from 'shared/Atoms';

const DialogWrapper = styled(MuiDialog)`
  z-index: 10000;

  .MuiBackdrop-root {
    ${({ hideBackground }) =>
    hideBackground &&
    css`
        opacity: 0 !important;
      `}
  }
`;

const Title = styled(DialogTitle)`
  height: 56px;
  border-bottom: 1px solid #edf1f7;

  .ButtonBack {
    position: absolute;
    left: 15px;
    top: 15px;
    color: #c5cee0;
    cursor: pointer;
  }

  .MuiButtonBase-root {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #c5cee0;
  }
`;

const Footer = styled(DialogActions)`
  border-top: 1px solid #edf1f7;
  padding: 12px 35px;
`;

const Body = styled(DialogContent)`
  padding: 16px 40px 23px;

  ${({ bodyCustomStyles }) => bodyCustomStyles}
`;

const Dialog = ({
  open,
  onClose,
  title,
  children,
  footer,
  hasBackButton = false,
  onBack,
  showHead = true,
  blurBackground = true,
  maxWidth = 'sm',
  bodyCustomStyles = '',
}) => {
  if (!open) return null;

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      hideBackground={!blurBackground}
    >
      {showHead && (
        <Title disableTypography className='d-flex justify-content-space-between w-100 text-center'>
          <Typography variant='h6'>{title}</Typography>
          {onClose ? <Icon name='close' onClick={onClose} className='text-gray' /> : null}
        </Title>
      )}
      <Body bodyCustomStyles={bodyCustomStyles}>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </DialogWrapper>
  );
};

export default Dialog;
