import { createRequestTypes, action } from 'modules/common/actions';

export const GET_DAY_REPORT = createRequestTypes('GET_DAY_REPORT');
export const GET_MONTH_REPORT = createRequestTypes('GET_MONTH_REPORT');

const reportsActions = {
  getDayReport: {
    request: (workPointId, date) => action(GET_DAY_REPORT.REQUEST, { workPointId, date }),
    success: data => action(GET_DAY_REPORT.SUCCESS, { payload: data }),
    failure: error => action(GET_DAY_REPORT.FAILURE, { payload: error }),
  },

  getMonthReport: {
    request: (workPointId, date) => action(GET_MONTH_REPORT.REQUEST, { workPointId, date }),
    success: data => action(GET_MONTH_REPORT.SUCCESS, { payload: data }),
    failure: error => action(GET_MONTH_REPORT.FAILURE, { payload: error }),
  },
};

export default reportsActions
