import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, isAuthenticated, role, path, ...rest }) => {
  const redirectPath = isAuthenticated || '/';

  // const history = useHistory();
  // const dispatch = useDispatch();
  // const { user } = useSelector(state => state.userReducer);

  // useEffect(() => {
  //   if (isAuthenticated === true && user.hasOwnProperty('onboardingProcessCompleted') === false &&
  //     // Object.values(routes).includes(history.location.pathname)
  //   ) {
  //     dispatch(usersActions.getUser.request('', true))
  //   }
  // }, [])

  // if (
  //   isAuthenticated === true &&
  //   !user.hasOwnProperty('onboardingProcessCompleted') &&
  //   // Object.values(routes).includes(history.location.pathname)
  // ) {
  //   return null;
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export const PublicRoute = ({ component: Component, isAuthenticated, role, ...rest }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  // const { user } = useSelector(state => state.userReducer)
  // const { medicalRecordRequests } = useSelector(state => state.providerReducer);

  let redirectPath = '/reports/day';

  // useEffect(() => {
  //   if (isAuthenticated === true && user.hasOwnProperty('onboardingProcessCompleted') === false) {
  //     dispatch(usersActions.getUser.request('', true))
  //   }
  // }, [])

  // if (Object.values(commonRoutes).includes(history.location.pathname)) {
  //   isAuthenticated = false
  // }

  // if (isAuthenticated === true && user.hasOwnProperty('onboardingProcessCompleted')) {
  //   if (user.onboardingProcessCompleted === false) {
  //     redirectPath = getNextAuthRouteAfterSignIn(user, medicalRecordRequests, routes, MEDICAL_RECORD_REQUEST_STATUS);
  //   } else {
  //     redirectPath = '/overview';
  //   }
  // }

  // if (isAuthenticated === true && redirectPath === null) {
  //   return null;
  // }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? <Redirect to={redirectPath} /> : <Component {...props} />
      }
    />
  );
};
