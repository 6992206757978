import clsx from 'clsx';
import { CircularProgress, Button } from '@mui/material';

const ButtonMainAccent = ({ children, loading, makedStyles, ...props }) => (
  <Button color='primary' className={clsx(makedStyles)} {...props}>
    {loading && <CircularProgress className='text-white mr-10' size={20} />}
    {children}
  </Button>
);

export default ButtonMainAccent;
