import React from 'react';

import './CustomerYearsOldBlock.scss';


class CustomerYearsOldBlock extends React.Component {

    render() {

        return (
            <div className="customers_years_old">

                <div className="new">
                    <h3>Новые</h3>
                    <table>
                        <thead>
                            <tr>
                                <td>итого</td>
                                <td>{this.props.totalNew.man}</td>
                                <td>{this.props.totalNew.woman}</td>
                            </tr>

                            <tr>
                                <td></td>
                                <td><span style={{ background: '#7CCAF9', padding: '0 3px' }}>М</span></td>
                                <td><span style={{ background: '#E01313', padding: '0 3px' }}>Ж</span></td>
                            </tr>

                        </thead>

                        <tbody>
                            {
                                this.props.allNew.map(item => {
                                    return (
                                        <tr>
                                            <td>{item.years}</td>
                                            <td style={{ borderRight: '1px solid rgba(0, 0, 0, 0.5)' }}>{item.total.man}</td>
                                            <td>{item.total.woman}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="old">
                    <h3>Старые</h3>
                    <table>
                        <thead>

                            <tr>
                                <td>{this.props.totalOld.man}</td>
                                <td>{this.props.totalOld.woman}</td>
                            </tr>

                            <tr>
                                <td><span style={{ background: '#7CCAF9', padding: '0 3px' }}>М</span></td>
                                <td><span style={{ background: '#E01313', padding: '0 3px' }}>Ж</span></td>
                            </tr>

                        </thead>

                        <tbody>
                            {
                                this.props.allOld.map(item => {
                                    return (
                                        <tr>
                                            <td style={{ borderRight: '1px solid rgba(0, 0, 0, 0.5)' }}>{item.total.man}</td>
                                            <td>{item.total.woman}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}


export default CustomerYearsOldBlock;