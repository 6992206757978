import { createRequestTypes, action } from 'modules/common/actions';

export const SIGNUP = createRequestTypes('SIGNUP');
export const SIGNIN = createRequestTypes('SIGNIN');
export const SIGNOUT = createRequestTypes('SIGNOUT');

export const CONFIRM_SIGNIN = createRequestTypes('CONFIRM_SIGNIN');

export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD');
export const CHANGE_PASSWORD = createRequestTypes('CHANGE_PASSWORD');

const authActions = {
  signup: {
    request: (data, history) => action(SIGNUP.REQUEST, { payload: data, history }),
    success: (data) => action(SIGNUP.SUCCESS, { payload: data }),
    failure: (error) => action(SIGNUP.FAILURE, { payload: error }),
  },

  signin: {
    request: (data, history) => action(SIGNIN.REQUEST, { payload: data, history }),
    success: (data) => action(SIGNIN.SUCCESS, { payload: data }),
    failure: (error) => action(SIGNIN.FAILURE, { payload: error }),
  },

  signout: {
    request: (data) => action(SIGNOUT.REQUEST, { payload: data }),
    success: (data) => action(SIGNOUT.SUCCESS, { payload: data }),
    failure: (error) => action(SIGNOUT.FAILURE, { payload: error }),
  },

  forgotPassword: {
    request: (data) => action(FORGOT_PASSWORD.REQUEST, { payload: data }),
    success: (data) => action(FORGOT_PASSWORD.SUCCESS, { payload: data }),
    failure: (error) => action(FORGOT_PASSWORD.FAILURE, { payload: error }),
  },

  changePassword: {
    request: (data) => action(CHANGE_PASSWORD.REQUEST, { payload: data }),
    success: (data) => action(CHANGE_PASSWORD.SUCCESS, { payload: data }),
    failure: (error) => action(CHANGE_PASSWORD.FAILURE, { payload: error }),
  },
};

export default authActions;