import './TopBlueBlock.scss';

const MainStatsCard = ({ header, leftHeader, rightHeader, leftData, rightData, leftAgo, rightAgo, ago }) => {

	let agoData;
	if (ago === 'week') {
		agoData = 'неделю';
	} else {
		agoData = 'месяц';
	}

	return (
		<div id="top_blue_block">
			<h3>{header}</h3>
			<div className="data">
				<div className="left">
					<h4>{leftHeader}</h4>
					<div className="data">
						{leftData}
					</div>
					<div className="week_ago">
						{leftAgo} {agoData} назад
					</div>
				</div>
				<div className="right">
					<h4>{rightHeader}</h4>
					<div className="data">
						{rightData}
					</div>
					<div className="week_ago">
						{rightAgo} {agoData} назад
					</div>
				</div>
			</div>
		</div>
	);
}

export default MainStatsCard
