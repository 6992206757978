import { createMuiTheme } from '@mui/material';
import { fontsMapper } from './constants';

const fontList = Object.keys(fontsMapper).map((fontFamily) => ({
  fontFamily,
  src: `url(${fontsMapper[fontFamily]['font']})`,
}));

const stlyeObj = Object.keys(fontsMapper).reduce((result, fontFamily) => {
  const selector = fontsMapper[fontFamily].selectors.join(', ');
  result[selector] = { fontFamily: `${fontFamily} !important` };
  return result;
}, {});

// Open Sans
export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#B30101',
    },
    secondary: {
      main: '#bda8ff',
    },
  },

  typography: {
    fontFamily: 'Comfortaa-Bold',//'Exo2-Bold',
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontList,
        ...stlyeObj,
      },
    },
  },
});

